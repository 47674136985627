import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { HelperService, ApiService } from "@app/core";
import { TemplateService } from '../modules/template-library/template.service';
import { UpgradePlanComponent } from '../shared/components/modal/upgrade-plan/upgrade-plan.component';

@Component({
  selector: 'app-auth',
  template: `
    <div class="container mx-auto px-4 py-4 min-h-90-vh lg:min-h-70-vh text-center">
      <div class="mt-8"><i class="fa fa-spinner fa-spin fa-2x"></i></div>
    </div>`
})
export class AuthComponent implements OnInit {

  authToken: any = null;

  constructor(
    private activeRoute: ActivatedRoute,
    private helperService: HelperService,
    private templateService: TemplateService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.init();
  }

  init() {

    let isLoading = false;

    // Done it for SSR in case of hard refresh. Check Auth guard.
    this.activeRoute.queryParams.subscribe(params => isLoading = params.loading);
    if (isLoading) return;

    this.authToken = this.activeRoute.snapshot.params.auth_token;

    if (!this.authToken) this.helperService.navigateToUrl('/login');
    localStorage.setItem('authToken', this.authToken);

    var dataString: any = localStorage.getItem('redirectAfterLogin');
    localStorage.removeItem('redirectAfterLogin');
    
    if (!dataString) {
      this.redirectToProject();
    } else {
      let dataObject = JSON.parse(dataString);
      this.afterLoginOperationHandler(dataObject);
    }
  }

  afterLoginOperationHandler(dataObject) {
    
      if (dataObject && dataObject.action == 'copy_template') {
        this.startCopyProcess(dataObject.data);
      }

      else if (dataObject && dataObject.action == 'copy_premium_template') {

        this.apiService.get('me').subscribe((user) => {

          if ((!user.default_team && user.subscription && !user.onTrial) ||
            (user.default_team && user.canDefaultTeamMemberCopyPremiumTemplate)) {
            this.startCopyProcess(dataObject.data);
          } else {
            this.helperService.navigateToUrl('/pricing');
          }
        })
      }

      else if (dataObject && dataObject.action == 'member_invited') {
        this.helperService.navigateToUrl('/team-docs/teams/' + dataObject.data);
      }

      else if (dataObject && dataObject.action == 'pro') {

        this.helperService.navigateToUrl('/subscription/pro');
      }

      else if (dataObject && dataObject.action == 'team-docs') {

        this.helperService.navigateToUrl('/subscription/team-docs');
      }

      else {
        this.redirectToProject();
      }
  }

  startCopyProcess(template_uid: string) {

    this.helperService.blockUI('Copying Template, this may take a minute ...');

    this.templateService.copy(template_uid).subscribe((response: any) => {

      if (response && response.error == true) {

        if (response.error_type == 'UPGRADE_PLAN') {
          this.helperService.navigateToUrl('template-library');
          this.helperService.openModal(UpgradePlanComponent)
          this.helperService.unBlockUI();
        } else if (response.error_type == "TEAM_PERMISSION_ACCESS_DENIED") {
          this.helperService.navigateToUrl('projects');
          this.helperService.errorMessage("You don't have permission to perform this action as you have Contributor permissions. Kindly contact to your team owner to perform this action.");
          this.helperService.unBlockUI();
        } else {
          this.helperService.navigateToUrl('login');
          this.helperService.unBlockUI();
          this.helperService.errorMessage(response.message);
        }
      } else {
        if (response.existingTemplateCountWithSameName > 0) {
          this.helperService.successMessage(
            'The older version of your ' + response.userTemplate.name + ' has been archived. To view that template, select "Show Archived"',
            '',
            {
              timeOut : 10000
            }
          )
        }
        this.helperService.navigateToUrl('user-templates?copy=true&uid=' + response.userTemplate.uid);
        this.helperService.unBlockUI();
      }
    });
  }

  redirectToProject() {
    var route="";
    this.activeRoute.queryParams.subscribe(params => {
       route =params.route;
    });
    if(route=="projects"){
      this.helperService.navigateToUrl('/projects'); 
    }else if(route=="user-templates"){
      this.helperService.navigateToUrl('/user-templates'); 
    }else if(route=="template-library"){
      this.helperService.navigateToUrl('/template-library'); 
    }else{
      this.helperService.navigateToUrl('/projects');
    }
  }
}
