import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TemplateService } from '../../template.service';
import { AuthService, HelperService, MetaService } from '@app/core';
import { UpgradePlanComponent } from '@app/shared/components/modal/upgrade-plan/upgrade-plan.component';
import{ShareModalComponent} from  '../../modals/share-modal/share-modal.component';

declare var $: any;
declare var window:any;

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html'
})
export class OverviewComponent implements OnInit {

  data: any = {};
  isProcessing: boolean = false;
  showWorkflowGuidePopup: boolean = true;
  dataTemplateUrl:any;
  dataMailMessage:any;
  dataMailSubject:any;
  openPremiumTemplateAccuontUpgradePopup: boolean = false;
 
  constructor(
    private route: ActivatedRoute,
    private templateService: TemplateService,
    private helperService: HelperService,
    private metaService: MetaService,
    public authService: AuthService
  ) {

    this.dataTemplateUrl = this.helperService.siteUrl('/template-library/'+ this.route.snapshot.params['slug']);
    this.getTemplateBySlug(this.route.snapshot.params['slug']);
  }

  ngOnInit(): void {
    /*
     # Appending CKEditor js on runtime because it contain the styling  details (css classes). 
     # Also we donot want to load this on everypage So appending while loading the page.
   */
    let library = '/assets/js/ckeditor/ckeditor.js';

    if (document.querySelectorAll('[src="' + library + '"]').length == 0) {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = library;
      document.head.appendChild(script1);
    }

    setTimeout(() => {
      if( window.__sharethis__){
        window.__sharethis__.initialize();
      }
    }, 2500);

  }
  
  getTemplateBySlug(slug: string) {

    let request = this.templateService.findBySlug(slug);

    request.subscribe((response: any) => {
      if (response && response.error == false) {
        this.data.template = response.template;
        this.dataMailMessage = "I thought this "+this.data.template.name+" template would be of interest to you. It's a short-form narrative designed for Google docs \n "+ this.dataTemplateUrl;
        this.dataMailSubject = this.data.template.name + " template"; 
        this.data.permissions = response.permissions
        this.metaService.updateTitle(this.data.template.page_title + ' | Template Library | StartDeck');
        this.metaService.updateMetaDescription(this.data.template.seo_description);
        this.metaService.updateKeywords(this.data.template.seo_keywords);
        this.isProcessing = true;

        var self = this;
        $(document).scroll(function () {
          if (self.showWorkflowGuidePopup) {
            if ($('#bottomFixedStop').offset().top + $('#bottomFixedStop').height() >= $('#bottomFixedStopArea').offset().top - 10)
              $('#bottomFixedStop').css({ 'position': 'unset', 'transform': 'unset', 'margin': '0 auto' });

            if ($(document).scrollTop() + window.innerHeight < $('#bottomFixedStopArea').offset().top) {
              $('#bottomFixedStop').css({ 'position': 'fixed', 'transform': 'translateX(-50%)' }); // restore when you scroll up
              $('#bottomFixedStopArea').css({ 'height': '7rem' });
            } else {
              $('#bottomFixedStopArea').css({ 'height': '0' });
            }
          }
        });
        
      } else {
        this.helperService.navigateToUrl('template-library');
        this.helperService.errorMessage(response.message);
        this.isProcessing = true;
      }
    });
  }

  copyTemplate() {
    if (this.authService.isLoggedIn) {
      this.doCopy();
      return;
    }

    if (!this.authService.isLoggedIn) {
      if (this.data.template.is_premium) {
        this.helperService.navigateToUrl('/login?action=copy_premium_template&data=' + this.data.template.uid);
      } else {
        this.helperService.navigateToUrl('/login?action=copy_template&data=' + this.data.template.uid);
      }
      return;
    }
  }

  doCopy() {

    this.helperService.blockUI('Creating copy, this may take a minute ...');

    this.isProcessing = true;

    this.templateService.copy(this.data.template.uid).subscribe((response: any) => {

      if (response && response.error == true) {

        if (response.error_type == 'UPGRADE_PLAN') {
          this.helperService.openModal(UpgradePlanComponent)
          this.helperService.unBlockUI();
          this.isProcessing = false;
        }
        else if (response.error_type == 'PREMIUM_TEMPLATE') {
          this.openPremiumTemplateAccuontUpgradePopup = true;
          this.helperService.unBlockUI();
        } else {
          this.helperService.unBlockUI();
          this.helperService.errorMessage(response.message);
          this.isProcessing = false;
        }
      } else {
        if (response.existingTemplateCountWithSameName > 0) {
          this.helperService.successMessage(
            'The older version of your ' + response.userTemplate.name + ' has been archived. To view that template, select "Show Archived"',
            '',
            {
              timeOut : 10000
            }
          )
        }

        
        this.helperService.navigateToUrl('user-templates?copy=true&uid=' + response.userTemplate.uid);
        this.helperService.unBlockUI();
        this.isProcessing = false;
      }
    });
  }


}
